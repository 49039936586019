<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      logs: [],
      asset: {},
      page: 1,
      limit: 20,
      total_pages: 0,
    };
  },
  methods: {
    formatedTimestamp(unformatedDate) {
      const d = new Date(unformatedDate);
      const time = d.toTimeString().split(" ")[0];
      return `${unformatedDate.split("T")[0]} ${time.split(":")[0]}:${
        time.split(":")[1]
      }`;
    },
    get(page) {
      this.http
        .post("fixed-assets/logs/pagination", {
          limit: this.limit,
          page,
          asset_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.logs = res.data;
          this.asset = res.asset;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/fixed-assets`">
            <h4 class="m-0">{{ $t("fixed_assets.title") }}</h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $i18n.locale == "ar"
                ? asset.name
                : asset.name_en + " " + $t("fixed_assets.transfers")
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4"></div>
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t("fixed_assets.logs.id") }}</th>
          <th scope="col">{{ $t("fixed_assets.logs.from") }}</th>
          <th scope="col">{{ $t("fixed_assets.logs.to") }}</th>
          <th scope="col">{{ $t("fixed_assets.logs.time") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="(log, index) in logs" :key="log">
          <td scope="col">{{ index + 1 }}</td>
          <td scope="col">
            {{
              $i18n.locale == "ar"
                ? log?.from_branch?.name
                : log?.from_branch?.name_en
            }}
          </td>
          <td scope="col">
            {{
              $i18n.locale == "ar"
                ? log?.to_branch?.name
                : log?.to_branch?.name_en
            }}
          </td>
          <td scope="col">{{ formatedTimestamp(log?.created) }}</td>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<style scoped></style>
